@use "sass:meta" as ---gipmubwz3ll;//Screen size
$width-220: 220px;
$width-319: 319px;
$width-320: 320px;
$width-480: 480px;
$width-481: 481px;
$width-640: 640px;
$width-641: 641px;
$width-960: 960px;
$width-961: 961px;
$width-1023: 1023px;
$width-1024: 1024px;
$width-1025: 1025px;
$width-1281: 1281px;
$width-1370: 1370px;

//Color
$gray-light: #9e9e9e;
// $gray-dark: rgba(28, 28, 28, 1);
$gray-dark: #111;
$white: #fff;
$black-light: #2c2c2c;
$gray: #666;
$red: #f14643;
$blue: #009ada;
$pink: #ee81b3;
$yellow: #fff22a;
$green: #00a851;
$orange: #ffa500;

//Common font style
$font-size: 2rem;
$font-weight: 900;
$line-height: 2.5rem;
$letter-spacing: 0.02rem;
$font-family: Gotham-black;
$font-family-bold: Gotham-bold;
$font-family-book: Gotham-book;

$padding-1: 1rem;
$gap-1: 1rem;

;@include ---gipmubwz3ll.load-css("sass-embedded-legacy-load-done:0");